import React, { useLayoutEffect } from "react";
import "./tailwind.min.scss";
import {
  FaFileExcel,
  FaFileAlt,
  FaMoneyBill,
  FaClipboardCheck,
  FaFile,
  FaAddressCard,
  FaHome,
  FaCity,
  FaTractor,
  FaBuilding,
  FaWarehouse,
  FaDesktop,
  FaMoneyCheck,
  FaFileInvoice,
  FaNewspaper,
  FaCertificate,
  FaFish,
  // FaCertificate,
  // FaNewspaper,
} from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { fetchApplications } from "../_redux/slices/application";
import { FiUserCheck } from "react-icons/fi";

const ApplicationModules = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const nagarpalika = useSelector((state) => state.nagarpalika?.nagarpalika);

  useLayoutEffect(() => {
    dispatch(fetchApplications());
  }, [dispatch]);

  const handleNavigate = async (app) => {
    try {
      let redirectUrl = app.domain;
      if (app.applicationId) {
        let link = `/o/consent/${app.applicationId}`;
        if (process.env.NODE_ENV === "development") {
          link += `?development=true`;
          redirectUrl = app.dev_url;
        }
        history.push(link);
      } else {
        const redirectLink = document.createElement("a");
        redirectLink.href = redirectUrl;
        redirectLink.click();
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <div className="use-tailwind">
      <section className="relative">
        <div className="grid grid-cols-1 text-center mb-8">
          <h3 className=" md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
            डिजिटल{" "}
            {nagarpalika?.name_np ? nagarpalika?.name_np?.split(" ")[0] : ""}{" "}
            मोड्युल
          </h3>
          <p className="text-slate-400 max-w-xl mx-auto">
            डिजिटल{" "}
            {nagarpalika?.name_np ? nagarpalika?.name_np?.split(" ")[0] : ""}{" "}
            प्रणालीमा प्रयोग भएका मोड्युलहरु को बिस्तृत जानकारी ।
          </p>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-6 md:grid-cols-4 gap-y-4">
          {APPLICATION_LIST.map((app) => {
            return (
              <div
                key={app.label}
                className={`shadow-xl shadow-gray-100 dark:hover:shadow-gray-800 transition duration-500 rounded-2xl text-center ${
                  app.enabled ? "" : "opacity-50 pointer-events-none"
                } `}
              >
                <a
                  href={app.enabled ? app.href : "#"}
                  onClick={(e) => {
                    if (app.applicationId) {
                      e.preventDefault();
                      handleNavigate(app);
                    }
                  }}
                  className="group cursor-pointer"
                >
                  <div className="w-20 h-20 text-white bg-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                    <div className="text-4xl">{app.icon}</div>
                  </div>
                  <div
                    className="title text-slate-900 h5 text-lg font-medium content mt-2 group-hover:text-indigo-600 w-[20px] m-auto"
                    style={{ maxWidth: "130px" }}
                  >
                    {app.label}
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default ApplicationModules;

// Siyari;
const APPLICATION_LIST = [
  {
    id: 1,
    label: "सबै सिफारिस सेवा",
    href: "https://sifarish.siyarimun.gov.np/login",
    icon: <FaFileAlt />,
    applicationId: 2734918268391893,
    enabled: true,
  },
  {
    id: 2,
    label: "दर्ता चलानी प्रणाली",
    href: "https://dartachalani.siyarimun.gov.np/login",
    icon: <FaFileExcel />,
    applicationId: 2838897297357477,
    enabled: true,
  },
  {
    id: 9,
    label: "पत्राचार प्रणाली",
    href: "https://patrachar.siyarimun.gov.np/login",
    icon: <FaFile />,
    applicationId: 2199710875942619,
    enabled: true,
  },
  {
    id: 8,
    label: "परिचयपत्र (अपाङ्गता/जेष्ठ नागरिक)",
    href: "https://disability.siyarimun.gov.np/login",
    icon: <FaAddressCard />,
    enabled: true,
  },
  {
    id: 4,
    label: "संघ संस्था दर्ता प्रणाली",
    href: "https://certification.siyarimun.gov.np/login",
    icon: <FaBuilding />,
    applicationId: 1315945161522812,
    enabled: true,
  },
  {
    id: 10,
    label: "सम्पत्ति कर प्रणाली",
    href: "http://siyarirmun.tax.lgerp.org/Home/login",
    icon: <FaClipboardCheck />,
    enabled: true,
  },
  {
    id: 12,
    label: "नगदी रसिद प्रणाली",
    href: "http://siyarirmun.acc.lgerp.org/Home/login",
    icon: <FaMoneyBill />,
    enabled: true,
  },
  {
    id: 13,
    label: "जिन्सी प्रणाली",
    href: "https://pams.fcgo.gov.np/#/",
    icon: <IoIosPeople />,
    enabled: true,
  },
  {
    id: 14,
    label: "लेखा प्रणाली",
    href: "https://sutra.fcgo.gov.np/",
    icon: <FaWarehouse />,
    enabled: true,
  },
  {
    id: 15,
    label: "राजस्व प्रणाली",
    href: "http://siyarirmun.acc.lgerp.org/Home/Login",
    icon: <FaDesktop />,
    enabled: true,
  },
  {
    id: 16,
    label: "सुत्र",
    href: "https://sutra.fcgo.gov.np/sutra2/login",
    icon: <FaMoneyCheck />,
    enabled: true,
  },
  {
    id: 17,
    label: "PMEP EMIS प्रणाली",
    href: "https://emis.pmep.gov.np/login",
    icon: <FaFileInvoice />,
    enabled: true,
  },
  {
    id: 17,
    label: "माछा पोखरी व्यवस्थापन प्रणाली",
    href: "http://139.59.34.248:8011/",
    icon: <FaFish />,
    enabled: true,
  },
  {
    id: 18,
    label: "CEHRD EMIS प्रणाली",
    href: "http://iemis.cehrd.gov.np/login",
    icon: <FaFileInvoice />,
    enabled: true,
  },
  {
    id: 19,
    label: "किसान सुचिकरण प्रणाली",
    href: "https://farmer.moald.gov.np/",
    icon: <FaTractor />,
    enabled: true,
  },
  {
    id: 23,
    label: "ई हाजिरी प्रणाली",
    href: "https://siyarimun.eattendance.com.np/",
    icon: <FiUserCheck />,
    enabled: true,
  },
  {
    id: 20,
    label: "सामाजिक सुरक्षा",
    href: "https://online.donidcr.gov.np/Home/",
    icon: <FaMoneyBill />,
    enabled: true,
  },
  {
    id: 21,
    label: "घटना दर्ता",
    href: "https://ss.donidcr.gov.np/",
    icon: <FaFileInvoice />,
    enabled: true,
  },
  {
    id: 22,
    label: "डिजिटल घर-नक्शा प्रणाली",
    href: "#",
    icon: <FaBuilding />,
    enabled: true,
  },
  {
    id: 23,
    label: "सेवा अभिलेख",
    href: "#",
    icon: <FaBuilding />,
    enabled: true,
  },
  {
    id: 25,
    label: "योजना व्यवस्थापन प्रणाली",
    href: "#",
    icon: <FaDesktop />,
    enabled: true,
  },
  {
    id: 26,
    label: "कार्यपालिका व्यवस्थापन प्रणाली",
    href: "#",
    icon: <FaBuilding />,
    enabled: true,
  },
  {
    id: 24,
    label: "गुनासो तथा उजुरी दर्ता प्रणाली",
    href: "#",
    icon: <FaDesktop />,
    enabled: true,
  },
];

// Bansgadhi;
// const APPLICATION_LIST = [
//   {
//     id: 1,
//     label: "सबै सिफारिस सेवा",
//     href: "https://sifarish.bansgadhimun.gov.np/",
//     icon: <FaFileAlt />,
//     enabled: true,
//     applicationId: 3225913770662109,
//   },
//   {
//     id: 2,
//     label: "दर्ता चलानी",
//     href: "https://dartachalani.bansgadhimun.gov.np/",
//     icon: <FaFileExcel />,
//     enabled: true,
//     applicationId: 8886595933321659,
//   },
//   {
//     id: 3,
//     label: "घ वर्ग इजाजत-पत्र",
//     href: "https://ijajatpatra.bansgadhimun.gov.np/",
//     icon: <FaCertificate />,
//     enabled: true,
//   },
//   {
//     id: 4,
//     label: "व्यवसाय दर्ता / नबिकरण",
//     href: "https://certification.bansgadhimun.gov.np/",
//     icon: <FaBuilding />,
//     enabled: true,
//     applicationId: 6702521502285218,
//   },
//   {
//     id: 5,
//     label: "कृषक समूह दर्ता / नबिकरण",
//     href: "https://certification.bansgadhimun.gov.np/",
//     icon: <FaTractor />,
//     applicationId: 6702521502285218,
//     enabled: true,
//   },
//   {
//     id: 6,
//     label: "टोल विकास समूह दर्ता / नबिकरण",
//     href: "https://certification.bansgadhimun.gov.np/",
//     icon: <FaCity />,
//     applicationId: 6702521502285218,
//     enabled: true,
//   },
//   {
//     id: 7,
//     label: "बरघर संस्था दर्ता / नविकरण",
//     href: "https://certification.bansgadhimun.gov.np/",
//     icon: <FaHome />,
//     applicationId: 6702521502285218,
//     enabled: true,
//   },
//   {
//     id: 8,
//     label: "परिचयपत्र (अपाङ्गता/जेष्ठ नागरिक)",
//     href: "https://disability.bansgadhimun.gov.np/",
//     icon: <FaAddressCard />,
//     applicationId: 2450905311997549,
//     enabled: true,
//   },
//   {
//     id: 9,
//     label: "पत्राचार",
//     href: "https://patrachar.bansgadhimun.gov.np/",
//     icon: <FaFile />,
//     applicationId: 1966479989937500,
//     enabled: true,
//   },
//   {
//     id: 10,
//     label: "योजना",
//     href: "http://5.189.142.111:8588/",
//     icon: <FaClipboardCheck />,
//     enabled: true,
//   },
//   {
//     id: 11,
//     label: "घटना दर्ता",
//     href: "https://online.donidcr.gov.np/",
//     icon: <FaNewspaper />,
//     enabled: true,
//   },
//   {
//     id: 12,
//     label: "सामाजिक सुरक्षा",
//     href: "https://ss.donidcr.gov.np/",
//     icon: <FaMoneyBill />,
//     enabled: true,
//   },
//   {
//     id: 13,
//     label: "कार्यालयको हाजिरी",
//     href: "https://attendance.gov.np/",
//     icon: <IoIosPeople />,
//     enabled: true,
//   },
//   {
//     id: 14,
//     label: "जिन्सि ब्यबस्थापन",
//     href: "http://pams.fcgo.gov.np/",
//     icon: <FaWarehouse />,
//     enabled: true,
//   },
//   {
//     id: 15,
//     label: "करदाता पोर्टल",
//     href: "https://eservice.bansgadhimun.gov.np/",
//     icon: <FaDesktop />,
//     enabled: true,
//   },
//   {
//     id: 16,
//     label: "करसेवा व्यवस्थापन",
//     href: "http://tax.bansgadhimun.gov.np/Home/Login",
//     icon: <FaMoneyCheck />,
//     enabled: true,
//   },
//   {
//     id: 17,
//     label: "डिजिटल घटना दर्ता",
//     href: "http://digitization.docr.gov.np/Account/Login?ReturnUrl=%2F",
//     icon: <FaFileInvoice />,
//     enabled: true,
//   },
// ];

// Demo;
// const APPLICATION_LIST = [
//   {
//     id: 1,
//     label: "सबै सिफारिस सेवा",
//     href: "https://staging-sifarish.ibis.com.np/login",
//     icon: <FaFileAlt />,
//     applicationId: 2686233717370253,
//     enabled: true,
//   },
//   {
//     id: 2,
//     label: "दर्ता चलानी प्रणाली",
//     href: "https://staging-darta-chalani.ibis.com.np/login",
//     icon: <FaFileExcel />,
//     applicationId: 8450600139807023,
//     enabled: true,
//   },
//   {
//     id: 9,
//     label: "पत्राचार प्रणाली",
//     href: "https://staging-patrachar.ibis.com.np/login",
//     icon: <FaFile />,
//     applicationId: 8491969728550809,
//     enabled: true,
//   },
//   {
//     id: 8,
//     label: "परिचयपत्र (अपाङ्गता/जेष्ठ नागरिक)",
//     href: "https://staging-parichayapatra.ibis.com/login",
//     icon: <FaAddressCard />,
//     enabled: true,
//     applicationId: 7003224767625174,
//   },
//   {
//     id: 4,
//     label: "व्यवसाय दर्ता / नबिकरण",
//     href: "https://staging-certification.ibis.com.np/login",
//     icon: <FaBuilding />,
//     applicationId: 2601542375304146,
//     enabled: true,
//   },
//   {
//     id: 10,
//     label: "कृषक समूह दर्ता / नबिकरण",
//     href: "https://staging-certification.ibis.com.np/login",
//     icon: <FaTractor />,
//     applicationId: 2601542375304146,
//     enabled: true,
//   },
//   {
//     id: 11,
//     label: "टोल विकास समूह दर्ता / नबिकरण",
//     href: "https://staging-certification.ibis.com.np/login",
//     icon: <FaCity />,
//     applicationId: 2601542375304146,
//     enabled: true,
//   },
//   {
//     id: 12,
//     label: "बरघर संस्था दर्ता / नविकरण",
//     href: "https://staging-certification.ibis.com.np/login",
//     icon: <FaHome />,
//     applicationId: 2601542375304146,
//     enabled: true,
//   },
//   {
//     id: 13,
//     label: "HMIS",
//     href: "https://staging-hmis.ibis.com.np/login",
//     icon: <FaFileAlt />,
//     enabled: true,
//   },
//   {
//     id: 14,
//     label: "KMS",
//     href: "https://staging-kms.ibis.com.np/login",
//     icon: <FaCertificate />,
//     enabled: true,
//   },
//   {
//     id: 15,
//     label: "कृषि व्यवस्थापन प्रणाली",
//     href: "#",
//     icon: <FaTractor />,
//     enabled: true,
//   },
//   {
//     id: 16,
//     label: "घ वर्ग इजाजत-पत्र",
//     href: "#",
//     icon: <FaHome />,
//     enabled: true,
//   },
// ];

// Demo;
// const APPLICATION_LIST = [
//   {
//     id: 1,
//     label: "सबै सिफारिस सेवा",
//     href: "https://staging-sifarish.ibis.com.np/login",
//     icon: <FaFileAlt />,
//     applicationId: 2686233717370253,
//     enabled: true,
//   },
//   {
//     id: 2,
//     label: "दर्ता चलानी प्रणाली",
//     href: "https://staging-darta-chalani.ibis.com.np/login",
//     icon: <FaFileExcel />,
//     applicationId: 8450600139807023,
//     enabled: true,
//   },
//   {
//     id: 9,
//     label: "पत्राचार प्रणाली",
//     href: "https://staging-patrachar.ibis.com.np/login",
//     icon: <FaFile />,
//     applicationId: 8491969728550809,
//     enabled: true,
//   },
//   {
//     id: 8,
//     label: "परिचयपत्र (अपाङ्गता/जेष्ठ नागरिक)",
//     href: "https://staging-parichayapatra.ibis.com/login",
//     icon: <FaAddressCard />,
//     enabled: true,
//     applicationId: 7003224767625174,
//   },
//   {
//     id: 4,
//     label: "व्यवसाय दर्ता / नबिकरण",
//     href: "https://staging-certification.ibis.com.np/login",
//     icon: <FaBuilding />,
//     applicationId: 2601542375304146,
//     enabled: true,
//   },
//   {
//     id: 10,
//     label: "कृषक समूह दर्ता / नबिकरण",
//     href: "https://staging-certification.ibis.com.np/login",
//     icon: <FaTractor />,
//     applicationId: 2601542375304146,
//     enabled: true,
//   },
//   {
//     id: 11,
//     label: "टोल विकास समूह दर्ता / नबिकरण",
//     href: "https://staging-certification.ibis.com.np/login",
//     icon: <FaCity />,
//     applicationId: 2601542375304146,
//     enabled: true,
//   },
//   {
//     id: 12,
//     label: "बरघर संस्था दर्ता / नविकरण",
//     href: "https://staging-certification.ibis.com.np/login",
//     icon: <FaHome />,
//     applicationId: 2601542375304146,
//     enabled: true,
//   },
//   {
//     id: 13,
//     label: "HMIS",
//     href: "https://staging-hmis.ibis.com.np/login",
//     icon: <FaFileAlt />,
//     enabled: true,
//   },
//   {
//     id: 14,
//     label: "KMS",
//     href: "https://staging-kms.ibis.com.np/login",
//     icon: <FaCertificate />,
//     enabled: true,
//   },
//   {
//     id: 15,
//     label: "कृषि व्यवस्थापन प्रणाली",
//     href: "#",
//     icon: <FaTractor />,
//     enabled: true,
//   },
//   {
//     id: 16,
//     label: "घ वर्ग इजाजत-पत्र",
//     href: "#",
//     icon: <FaHome />,
//     enabled: true,
//   },
//   {
//     id: 22,
//     label: "सेवा अभिलेख",
//     link: "#",
//     icon: <FaBuilding />,
//     enabled: true,
//   },
// ];

// Staging;
// const APPLICATION_LIST = [
//   {
//     id: 1,
//     label: "सबै सिफारिस सेवा",
//     href: "https://staging-sifarish.ibis.com.np/login",
//     icon: <FaFileAlt />,
//     applicationId: 2686233717370253,
//     enabled: true,
//   },
//   {
//     id: 2,
//     label: "दर्ता चलानी प्रणाली",
//     href: "https://staging-darta-chalani.ibis.com.np/login",
//     icon: <FaFileExcel />,
//     applicationId: 8450600139807023,
//     enabled: true,
//   },
//   {
//     id: 9,
//     label: "पत्राचार प्रणाली",
//     href: "https://staging-patrachar.ibis.com.np/login",
//     icon: <FaFile />,
//     applicationId: 8491969728550809,
//     enabled: true,
//   },
//   {
//     id: 8,
//     label: "परिचयपत्र (अपाङ्गता/जेष्ठ नागरिक)",
//     href: "https://staging-parichayapatra.ibis.com/login",
//     icon: <FaAddressCard />,
//     enabled: true,
//     applicationId: 7003224767625174,
//   },
//   {
//     id: 4,
//     label: "व्यवसाय दर्ता / नबिकरण",
//     href: "https://staging-certification.ibis.com.np/login",
//     icon: <FaBuilding />,
//     applicationId: 2601542375304146,
//     enabled: true,
//   },
//   {
//     id: 10,
//     label: "कृषक समूह दर्ता / नबिकरण",
//     href: "https://staging-certification.ibis.com.np/login",
//     icon: <FaTractor />,
//     applicationId: 2601542375304146,
//     enabled: true,
//   },
//   {
//     id: 11,
//     label: "टोल विकास समूह दर्ता / नबिकरण",
//     href: "https://staging-certification.ibis.com.np/login",
//     icon: <FaCity />,
//     applicationId: 2601542375304146,
//     enabled: true,
//   },
//   {
//     id: 12,
//     label: "बरघर संस्था दर्ता / नविकरण",
//     href: "https://staging-certification.ibis.com.np/login",
//     icon: <FaHome />,
//     applicationId: 2601542375304146,
//     enabled: true,
//   },
//   {
//     id: 13,
//     label: "HMIS",
//     href: "https://staging-hmis.ibis.com.np/login",
//     icon: <FaFileAlt />,
//     enabled: true,
//   },
//   {
//     id: 14,
//     label: "KMS",
//     href: "https://staging-kms.ibis.com.np/login",
//     icon: <FaCertificate />,
//     enabled: true,
//   },
//   {
//     id: 15,
//     label: "कृषि व्यवस्थापन प्रणाली",
//     href: "#",
//     icon: <FaTractor />,
//     enabled: true,
//   },
//   {
//     id: 16,
//     label: "घ वर्ग इजाजत-पत्र",
//     href: "#",
//     icon: <FaHome />,
//     enabled: true,
//   },
// ];

// Mathagadhi;
// const APPLICATION_LIST = [
//   {
//     id: 1,
//     label: "सबै सिफारिस सेवा",
//     href: "https://sifarish.ibis.com.np/login",
//     icon: <FaFileAlt />,
//     applicationId: 3225913770662109,
//     enabled: true,
//   },
//   {
//     id: 2,
//     label: "दर्ता चलानी प्रणाली",
//     href: "#",
//     icon: <FaFileExcel />,
//     applicationId: 8450600139807023,
//     enabled: true,
//   },
//   {
//     id: 9,
//     label: "पत्राचार प्रणाली",
//     href: "#",
//     icon: <FaFile />,
//     applicationId: 8491969728550809,
//     enabled: true,
//   },
//   {
//     id: 8,
//     label: "परिचयपत्र (अपाङ्गता/जेष्ठ नागरिक)",
//     href: "#",
//     icon: <FaAddressCard />,
//     enabled: true,
//     applicationId: 7003224767625174,
//   },
//   {
//     id: 4,
//     label: "व्यवसाय दर्ता / नबिकरण",
//     href: "#",
//     icon: <FaBuilding />,
//     applicationId: 2601542375304146,
//     enabled: true,
//   },
//   {
//     id: 10,
//     label: "कृषक समूह दर्ता / नबिकरण",
//     href: "#",
//     icon: <FaTractor />,
//     applicationId: 2601542375304146,
//     enabled: true,
//   },
//   {
//     id: 11,
//     label: "टोल विकास समूह दर्ता / नबिकरण",
//     href: "#",
//     icon: <FaCity />,
//     applicationId: 2601542375304146,
//     enabled: true,
//   },
//   {
//     id: 12,
//     label: "बरघर संस्था दर्ता / नविकरण",
//     href: "#",
//     icon: <FaHome />,
//     applicationId: 2601542375304146,
//     enabled: true,
//   },
//   {
//     id: 13,
//     label: "HMIS",
//     href: "#",
//     icon: <FaFileAlt />,
//     enabled: true,
//   },
//   {
//     id: 14,
//     label: "KMS",
//     href: "#",
//     icon: <FaCertificate />,
//     enabled: true,
//   },
//   {
//     id: 15,
//     label: "कृषि व्यवस्थापन प्रणाली",
//     href: "#",
//     icon: <FaTractor />,
//     enabled: true,
//   },
//   {
//     id: 16,
//     label: "घ वर्ग इजाजत-पत्र",
//     href: "#",
//     icon: <FaHome />,
//     enabled: true,
//   },
// ];

// Duduwa
// const APPLICATION_LIST = [
// 	{
// 		"id": 1,
// 		"label": "दर्ता चलानी प्रणाली",
// 		"href": "https://dartachalaniduduwamun.ibis.com.np/login",
// 		"icon": <FaFileExcel />,
// 		"applicationId": 3357489989783087,
// 		"enabled": true
// 	},
// 	{
// 		"id": 2,
// 		"label": "सबै सिफारिस सेवा",
// 		'href': "#",
// 		"icon": <FaFileAlt />,
// 		"applicationId": 3225913770662109,
// 		"enabled": true
// 	},
// 	{
// 		"id": 9,
// 		"label": "पत्राचार प्रणाली",
// 		'href': "#",
// 		"icon": <FaFile />,
// 		"applicationId": 8491969728550809,
// 		"enabled": true
// 	},
// 	{
// 		"id": 8,
// 		"label": "परिचयपत्र (अपाङ्गता/जेष्ठ नागरिक)",
// 		'href': "#",
// 		"icon": <FaAddressCard />,
// 		"enabled": true,
// 		"applicationId": 7003224767625174,
// 	},
// 	{
// 		"id": 4,
// 		"label": "व्यवसाय दर्ता / नबिकरण",
// 		"href": "#",
// 		"icon": <FaBuilding />,
// 		"applicationId": 2601542375304146,
// 		"enabled": true
// 	},
// 	{
// 		"id": 10,
// 		"label": "कृषक समूह दर्ता / नबिकरण",
// 		"href": "#",
// 		"icon": <FaTractor />,
// 		"applicationId": 2601542375304146,
// 		"enabled": true
// 	},
// 	{
// 		"id": 11,
// 		"label": "टोल विकास समूह दर्ता / नबिकरण",
// 		"href": "#",
// 		"icon": <FaCity />,
// 		"applicationId": 2601542375304146,
// 		"enabled": true
// 	},
// 	{
// 		"id": 12,
// 		"label": "बरघर संस्था दर्ता / नविकरण",
// 		"href": "#",
// 		"icon": <FaHome />,
// 		"applicationId": 2601542375304146,
// 		"enabled": true
// 	},
// 	{
// 		"id": 13,
// 		"label": "HMIS",
// 		"href": "#",
// 		"icon": <FaFileAlt />,
// 		"enabled": true
// 	},
// 	{
// 		"id": 14,
// 		"label": "KMS",
// 		"href": "#",
// 		"icon": <FaCertificate />,
// 		"enabled": true
// 	},
// 	{
// 		"id": 15,
// 		"label": "कृषि व्यवस्थापन प्रणाली",
// 		"href": "#",
// 		"icon": <FaTractor />,
// 		"enabled": true
// 	},
// 	{
// 		"id": 16,
// 		"label": "घ वर्ग इजाजत-पत्र",
// 		"href": "#",
// 		"icon": <FaHome />,
// 		"enabled": true
// 	},
// ]

// chandannath
// const APPLICATION_LIST = [
//   {
//     id: 1,
//     label: "दर्ता चलानी प्रणाली",
//     href: "https://chandannath-darta.ibis.com.np/login",
//     icon: <FaFileExcel />,
//     applicationId: 1321655345092603,
//     enabled: true,
//   },
//   {
//     id: 2,
//     label: "सबै सिफारिस सेवा",
//     href: "https://chandannath-sifarish.ibis.com.np",
//     icon: <FaFileAlt />,
//     applicationId: 2306641310677572,
//     enabled: true,
//   },
//   {
//     id: 8,
//     label: "परिचयपत्र (अपाङ्गता/जेष्ठ नागरिक)",
//     href: "https://chandannath-samajikvatta.ibis.com.np",
//     icon: <FaAddressCard />,
//     enabled: true,
//     applicationId: 2481774875352908,
//   },
//   {
//     id: 4,
//     label: "व्यवसाय दर्ता / नबिकरण",
//     href: "https://chandannath-certification.ibis.com.np",
//     icon: <FaBuilding />,
//     applicationId: 7568789085207886,
//     enabled: true,
//   },
//   {
//     id: 11,
//     label: "टोल विकास समूह दर्ता / नबिकरण",
//     href: "https://chandannath-certification.ibis.com.np",
//     icon: <FaCity />,
//     applicationId: 7568789085207886,
//     enabled: true,
//   },
//   {
//     id: 12,
//     label: "बरघर संस्था दर्ता / नविकरण",
//     href: "https://chandannath-certification.ibis.com.np",
//     icon: <FaHome />,
//     applicationId: 7568789085207886,
//     enabled: true,
//   },
//   {
//     id: 16,
//     label: "घ वर्ग इजाजत-पत्र",
//     href: "https://chandannath-ijajatpatra.ibis.com.np",
//     icon: <FaHome />,
//     enabled: true,
//   },
//   {
//     id: 21,
//     label: "घटना दर्ता",
//     href: "https://ss.donidcr.gov.np/",
//     icon: <FaFileInvoice />,
//     enabled: true,
//   },
// ];

// rabdikot
// const APPLICATION_LIST = [
//   {
//     id: 1,
//     label: "दर्ता चलानी प्रणाली",
//     href: "https://rabdikot-darta.ibis.com.np/login",
//     icon: <FaFileExcel />,
//     applicationId: 1321655345092603,
//     enabled: true,
//   },
//   {
//     id: 2,
//     label: "सबै सिफारिस सेवा",
//     href: "https://rabdikot-sifarish.ibis.com.np",
//     icon: <FaFileAlt />,
//     applicationId: 2306641310677572,
//     enabled: true,
//   },
//   {
//     id: 8,
//     label: "परिचयपत्र (अपाङ्गता/जेष्ठ नागरिक)",
//     href: "https://rabdikot-samajikvatta.ibis.com.np",
//     icon: <FaAddressCard />,
//     enabled: true,
//     applicationId: 2481774875352908,
//   },
//   {
//     id: 4,
//     label: "व्यवसाय दर्ता / नबिकरण",
//     href: "https://rabdikot-certification.ibis.com.np",
//     icon: <FaBuilding />,
//     applicationId: 7568789085207886,
//     enabled: true,
//   },
//   {
//     id: 10,
//     label: "कृषक समूह दर्ता / नबिकरण",
//     href: "#",
//     icon: <FaTractor />,
//     applicationId: 7568789085207886,
//     enabled: true,
//   },
//   {
//     id: 11,
//     label: "टोल विकास समूह दर्ता / नबिकरण",
//     href: "https://rabdikot-certification.ibis.com.np",
//     icon: <FaCity />,
//     applicationId: 7568789085207886,
//     enabled: true,
//   },
//   {
//     id: 12,
//     label: "बरघर संस्था दर्ता / नविकरण",
//     href: "https://rabdikot-certification.ibis.com.np",
//     icon: <FaHome />,
//     applicationId: 7568789085207886,
//     enabled: true,
//   },
//   {
//     id: 21,
//     label: "घटना दर्ता",
//     href: "https://ss.donidcr.gov.np/",
//     icon: <FaFileInvoice />,
//     enabled: true,
//   },
// ];

import { BiUser } from "react-icons/bi";
import { CgPassword } from "react-icons/cg";
import {
  FaBuilding,
  FaCalendar,
  FaHeadphones,
  FaRegBuilding,
} from "react-icons/fa";
import { ImOffice } from "react-icons/im";
import { MdApps } from "react-icons/md";

export const data = [
  {
    id: 6,
    title: "मेरो प्रोफाइल",
    title_en: "My Profile",
    icon: <BiUser />,
    path: "/settings/profile",
    rule: "view:profile",
  },
  {
    id: 1,
    title: "आर्थिक वर्ष",
    title_en: "Fiscal Year",
    icon: <FaCalendar />,
    path: "/fiscal-year/1",
    rule: "view:arthikBarsa",
  },
  {
    id: 2,
    title: "पालिकाको विवरण",
    title_en: "Nagarpalika Details",
    icon: <ImOffice />,
    path: "/nagarpalika/1",
    rule: "view:nagarpalikaDetails",
  },
  {
    id: 3,
    title: "वडा कार्यालयहरु",
    title_en: "Ward Offices",
    icon: <FaBuilding />,
    path: "/wards/1",
    rule: "view:wodaKaralayaHaru",
  },
  {
    id: 4,
    title: "शाखा कार्यालयहरु",
    title_en: "Section Offices",
    icon: <FaRegBuilding />,
    path: "/sections/1",
    rule: "view:sakhaKaryalayaHaru",
  },

  {
    id: 5,
    title: "एप्लिकेसन",
    title_en: "Application",
    icon: <MdApps />,
    path: "/application/1",
    rule: "view:applications",
  },

  {
    id: 7,
    title: "पासवर्ड परिवर्तन गर्नुहोस",
    title_en: "Change Password",
    icon: <CgPassword />,
    path: "/change-password",
    rule: "view:changePassword",
  },
  {
    id: 10,
    title: "सपोर्ट",
    title_en: "Support",
    icon: <FaHeadphones />,
    path: "/support",
  },
];
